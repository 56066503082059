import yargsParser from 'yargs-parser';
import {getQueriesFromUrl} from '@/custom/class/FunctionClasses/HelperFunctions.js';

export default function parseCurl(curl_request){
  try {
    
    curl_request = curl_request.replaceAll('\n', '')
    curl_request = curl_request.replaceAll('\\', '')
    curl_request = curl_request.replaceAll(' --location ', ' ')
    curl_request = curl_request.replaceAll(' --l ', ' ')
    
    let argvs = yargsParser(curl_request)

    const json = {
      header: {},
      method: 'GET'
    }
    
    function isJson(str){
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }

    const removeQuotes = (str) =>  str.replace(/['"]+/g, '')
    
    const stringIsUrl = (url) => {
      let t
      try {
        t = new URL(url);
      } catch (_) {
        return false;  
      }
      return t.protocol === "http:" || t.protocol === "https:";
    }
    
    const parseField = (string) => {
      return string.split(/: (.+)/)
    }
    
    const parseHeader = (header) => {
      let parsedHeader = {};
      if(Array.isArray(header)){
        header.forEach((item, index) => {
          const field = parseField(item)
          parsedHeader[field[0]] = field[1]
        })
      }else{
        const field = parseField(header)
        parsedHeader[field[0]] = field[1]
      }
    
      return parsedHeader;
    }
    
    const parseData = (data) => {
      let jsonObj = {}
      json.header['Content-Type'] = 'application/json'

      if(Array.isArray(data)){
        
        if(isJson(data[0])){
          data.forEach((item) => {
            const parsedItem = JSON.parse(item);
            jsonObj = {
              ...jsonObj,
              ...parsedItem
            }
          }) 
    
          return jsonObj;
        }

        if(data[0].includes('=')){
          return parseDataUrlEncode(data);
        }
      }else{
        if(isJson(data)){
          return JSON.parse(data)
        }
        if(data.includes('=')){
          return parseDataUrlEncode(data);
        }
        return data;
      }
    }
    
    const parseDataUrlEncode = (data) => {
      let jsonUrlEncoded = ''
      json.header['Content-Type'] = 'application/x-www-form-urlencoded'

      if(Array.isArray(data)){
          data.forEach((item, index) => {
              if(index === 0){
                jsonUrlEncoded = encodeURI(item);
              }else{
                jsonUrlEncoded = jsonUrlEncoded + '&' + encodeURI(item); 
              }
          }) 
          return jsonUrlEncoded;
      }else{
        return data;
      }
    }
        
    for(const argv in argvs){
      switch (argv) {
        case '_':
          {
            const _ = argvs[argv];
            _.forEach(item => {
                item = removeQuotes(item)

                if(stringIsUrl(item)){
                  json.url = item ;
                }
            })
          }
          break;

        case 'X':
        case 'request': 
            json.method = argvs[argv]
          break;

        case 'H':
        case 'header': 
            {
              const parsedHeader = parseHeader(argvs[argv]);
              json.header = {
                ...json.header,
                ...parsedHeader
              }
            }
          break;

        case 'u':
        case 'user': 
            json.header['Authorization'] = argvs[argv]
          break;

        case 'A': 
        case 'user-agent': 
            json.header['user-agent'] = argvs[argv]
          break;

        case 'I': 
        case 'head': 
          json.method = 'HEAD'
          break;

        case 'b': 
        case 'cookie': 
          json.header['Set-Cookie'] = argvs[argv]
          break;

        case 'd': 
        case 'data': 
        case 'data-raw':
        case 'data-ascii': 
          json.data = parseData(argvs[argv])
          break;

        case 'data-urlencode': 
          json.data = parseDataUrlEncode(argvs[argv])
          break;

        case 'compressed': 
          if(!json.header['Accept-Encoding']){
            json.header['Accept-Encoding'] = argvs[argv] || 'deflate, gzip'
          }
          break;
        
        default:
          break;
      }
    }

    if (json.url){
      const queries = getQueriesFromUrl(json.url)
      json.url = json.url.split('?')[0]
      if (queries && Object.keys(queries).length > 0){
        json.query = queries
      }
      return json;
    } else {
      return undefined
    }
  } catch(err){
    console.error(err)
    return undefined
  }

} 