<template>
<div>
  <b-modal
    ref="import-curl-modal"
    size="lg"
    centered
    ok-only
    ok-variant="favorite"
    :ok-disabled="!curlData.parsed"
    @ok="$emit('import',curlData.parsed )"
  >
    <template #modal-title>
      Import Request from cURL
    </template>

    <div class="mb-1">
      <div class="ml-50 font-weight-bold">Request cURL:</div>
      <b-form-textarea class="bg-dark-blue text-light" autofocus max-rows="4" @input="parseCurlData()" v-model="curlData.curl" />
    </div>
    <!-- HTML_ANCHOR - import curl modal -->

    <div  class="ml-50 font-weight-bold">Result:</div>
    
    <div v-if="curlData.parsed && Object.keys(curlData.parsed).length > 0">
      <div class="bg-dark-blue rounded p-1">
        <div class="mb-1 d-flex align-items-center font-weight-bolder" >
          <h5 class="mb-0 request-item-label">URL:</h5>
          <div class="px-50 w-fit-content text-light border rounded ml-50 font-monospace">
              {{curlData.parsed.url}}
          </div>
        </div>

        <div v-if="curlData.parsed.header  && Object.keys(curlData.parsed.header).length > 0" class="mb-1">
          <h5 class="request-item-label">Headers:</h5>
          <div class="border-left-light ml-50">
            <div v-for="h in Object.keys(curlData.parsed.header)" :key="'curl-header-'+h" class="font-monospace">
              <b-icon icon="square-fill" variant="light" scale="0.3"/>
              <span class="">{{h}}</span>
              <span class="text-secondary mx-25">:</span>
              <span class="">{{curlData.parsed.header[h]}}</span>
            </div>
          </div>
        </div>
      
        <div v-if="curlData.parsed.query && Object.keys(curlData.parsed.query).length > 0" class="mb-1">
          <h5 class="request-item-label">Queries:</h5>
          <div class="border-left-light ml-50 font-monospace">
            <div v-for="h in Object.keys(curlData.parsed.query)" :key="'curl-query-'+h">
              <b-icon icon="square-fill" variant="light" scale="0.3"/>
              <span class="">{{h}}</span>
              <span class="text-secondary mx-25">:</span>
              <span class="">{{curlData.parsed.query[h]}}</span>
            </div>
          </div>
        </div>
        
        <div v-if="curlData.parsed.data">
          <h5 class="request-item-label">Body:</h5>
          <div class="border-left-light ml-50">
            <custom-pre :data="curlData.parsed.data" maxHeight="250px"/>
          </div>
        </div>

      </div>
    </div>
  
    <div v-else class="rounded bg-dark-blue py-2">  
      <h3 align="center" class="text-secondary mb-0">
        Input a valid cURL
      </h3>
    </div>

    <template #modal-ok>
      <b>Import Request</b>
    </template>    
  </b-modal>

</div>
</template>

<script>
import {
  BRow,
  BCollapse,
  BBadge,
  BDropdown, 
  BDropdownItem,
  BDropdownDivider,
  BFormInput,
  BSkeleton,
  BSpinner,
  BButton,
  BDropdownItemButton,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue';

import CustomPre from '@/views/pages/middleware/MiddlewareManagement/Components/CustomPre.vue';
import ParseCurl from "@/custom/class/FunctionClasses/ParseCurl.js"


export default {
  components: {
    BRow,
    BCollapse,
    BBadge,
    BDropdown, 
    BDropdownItem,
    BDropdownDivider,
    BFormInput,
    BSkeleton,
    BSpinner,
    BButton,
    BDropdownItemButton,
    BFormTextarea,
    BModal,
    ParseCurl,
    CustomPre,
  },
  data() {
    return {
      curlData:{
        curl: "",
        parsed: undefined,
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: undefined 
    },
  },
	computed: {
		vModel: {
			get() {
				return value
			},
			set(value) {
				this.$emit('input', value)
			}
		},
	},
  methods: {
    show() {
      this.curlData = {
        curl: "",
        parsed: {},
      }
      
      this.$refs['import-curl-modal'].show()
    },
    hide(){
      this.$refs['import-curl-modal'].hide()
    },
    parseCurlData(){
      let r = ParseCurl(this.curlData.curl)
      
      this.curlData.parsed = r
      this.vModel = r
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.request-item-label{
  font-weight: bolder;
  background-color: $light;
  width: fit-content;
  color: $black;
  border-radius: 5px;
  padding: 0 4px;
  margin-bottom: 0;
}
</style>