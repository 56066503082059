<template>
  <middleware-sidebar-outline
    :uuid="uuid"
    :middlewareID="middlewareID"
    :middlewareType="middlewareType"
    ref="middleware-sidebar-outline"
    @allowClosing="allowForceClosing = true"
    @close="closingSidebar"
    @shown="$emit('shown')"
    @saveMiddleware="buildAgents"
    v-model="variables"
  > 
    <div v-if="requesterData.method" >
      <div class="pt-25 d-flex flex-wrap justify-content-between align-items-start">
        <div>
          <div v-if="!isIntegrator && integratorRecommendation" class="text-secsondary">
            <!-- <div>
              <b-icon icon="box-seam"/>
              There is an Integrator Service that matches this Request
            </div> -->
            <div>
              <b-icon icon="star-fill" scale="0.65" variant="purple" class="mr-s25"/>
              Integrator recommended 
              <b-icon icon="arrow-right-short"/>
              <b-link class="hover-underline" :to="{name:'integrator-service-view', params:{serviceIdentifier: integratorRecommendation.identifier} }">
                {{integratorRecommendation.name}}
              </b-link>
            </div>
          </div>
        </div>
        <div>
          <b-button variant="none" class="import-curl-btn" @click="$refs['import-request-curl'].show()">
            <span class="px-50">
            Import from cUrl 
            </span>
            <div class="hover-indicator"/>
          </b-button>
        </div>
      </div>
      <b-row class="mt-1">
        <b-col md="8">
          <label for="custom-input">
            URL
            <helper-tooltip
              size="13"
              innerHTML="Full URL with scheme (https, http), host and path. Like <code>https://myhost.com/my/path</code>"
            />
          </label>
          <custom-input
            v-if="requesterData.uri != undefined && variables"
            :key="`custom-input-${uuid}-${version}`"
            v-model="requesterData.uri.register_1"
            :horizontal="true"
            :possibleValues="variables"
            @change="parseQueriesFromUrl()"
          />
          <div v-else>Loading...</div>
        </b-col>
        <b-col md="4" class="mt-25">
          <b-form-group :label="$t('Method')" label-for="method">
            <b-form-select
              v-model="requesterData.method.register_1.value"
            >
              <b-form-select-option
                v-for="(option, index) in options"
                :key="index"
                :value="option.script_output"
              >
                {{ $t(option.label) }}
              </b-form-select-option>
              <template #first>
                <b-form-select-option :value="null" disabled>
                  {{ $t("common.terms.select_please") }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <div class="w-100">
          <b-tabs justified>
            <b-tab :title="`Headers ` + (requesterData.headers.length>0 ? `(${requesterData.headers.length})` :'')">
            <!-- {{requesterData.headers.length}} -->
              <key-value
                v-if="variables"
                :ref="getID('header')"
                class="ml-1"
                :addButtonText="$t('+ add new header')"
                v-model="requesterData.headers"
                dropZone
                :possibleValues="variables"
                @deleteItem="
                  (payload) => {
                    deleteAgent(payload.item, payload.index, 'header');
                  }
                "
                :defaultValue="defaultHeaderValue"
              />
            </b-tab>
            <b-tab  :title="`Queries ` + (requesterData.queries.length>0 ? `(${requesterData.queries.length})` :'')">
              <key-value
                v-if="variables"
                :ref="getID('query')"
                class="ml-1"
                :addButtonText="$t('+ add new query')"
                v-model="requesterData.queries"
                dropZone
                :possibleValues="variables"
                @deleteItem="
                  (payload) => {
                    deleteAgent(payload.item, payload.index, 'query');
                  }
                "
                :defaultValue="defaultQueryValue"
              />
            </b-tab>
            <b-tab  :title="`Cookies ` + (requesterData.cookies.length>0 ? `(${requesterData.cookies.length})` :'')">
              <key-value
                v-if="variables"
                :ref="getID('cookie')"
                class="ml-1"
                :addButtonText="$t('+ add new Cookie')"
                v-model="requesterData.cookies"
                dropZone
                :possibleValues="variables"
                @deleteItem="
                  (payload) => {
                    deleteAgent(payload.item, payload.index, 'cookie');
                  }
                "
                :defaultValue="defaultPathValue"
              />
            </b-tab>
          </b-tabs>
        </div>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr class="w-100">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-start">
              <b-form-checkbox
                class="custom-control-primary mb-50"
                v-model="hasBody"
              >
                Has body?
              </b-form-checkbox>
              <div>
              
                <b-button variant="none" class="px-0 square py-25 mr-1 ml-2  border-bottom-secondary" @click="$refs['certificates-modal'].show()">
                  <span class="text-secondary" v-if="requesterData.certificate.register_1.value.length == 0  || requesterData.certificate.register_1.value == ' '">
                    <b-icon icon="plus-circle"/>
                    Add Certificate
                  </span>
                  <span v-else class="text-light">
                    <b-icon icon="check" variant="success" scale="1.3"/> Certificate:
                    <b class="text-favorite">{{requesterData.certificate.register_2.value}}</b> 
                  </span>
                </b-button>
                <b-button 
                  variant="outline-sdanger" 
                  class=" py-25 px-25  round " 
                  v-if="requesterData.certificate.register_1.value.length != 0   && requesterData.certificate.register_1.value != ' '"
                  @click="clearCertificate()"
                >
                  <b-icon icon="x-circle" scale="1.1" variant="danger"/>
                </b-button>
              </div>
            </div>
            <b-form-checkbox
              switch
              class="custom-control-primary mb-50"
              v-model="advancedBody"
            >
              Advanced
              <helper-tooltip
                size="15"
                innerText="eopae"
                tooltipPlacement="right"
              />
            </b-form-checkbox>
          </div>
          <b-form-group
            v-if="!advancedBody && variables"
            :label="$t(type === 'json' ? 'middleware.modal.input.label.json_body' : 'middleware.modal.input.label.request_body')"
            label-for="detect-json"
            class="w-100"
            :class="hasBody ? '' : 'json-input-disabled'"
          >
            <json-editor
              v-if="type === 'json'"
              
              key="detect-json"
              v-model="requesterData.body.register_1.value"
              :modes="['code', 'tree', 'preview']"
              height="400px"
            />
  
            <key-value
              v-else-if="type === 'form-data' || type === 'url-encoded'"
              :ref="getID('body')"
              class=""
              :addButtonText="$t('+ add new field')"
              v-model="requesterData.body"
              dropZone
              :possibleValues="variables"
              isFormDataBody
              @deleteItem="
                (payload) => {
                  deleteAgent(payload.item, payload.index, 'body');
                }
              "
              :defaultValue="defaultBodyValue"
            />
            <template v-else>
                <monaco 
                  class="border"
                  v-if="type == 'xml'"
                  language="xml"
                  height="400px"
                  v-model="requesterData.body.register_1.value"
                />
  
                <monaco 
                  class="border"
                  v-else
                  language="raw"
                  height="400px"
                  v-model="requesterData.body.register_1.value"
                />
            </template>
              
          </b-form-group>
          <div v-else>
            <actions-reorder-list
              :key="getID(`actions-reorder-list-${version}`)"
              :middlewareID="middlewareID"
              v-model="agents"
              @agentAdded="version++"
              @agentEdited="version++"
              @agentDeleted="version++"
            />
          </div>

        </b-col>
      </b-row>
    </div>
    <import-request-curl ref="import-request-curl" @import="importFromCurl"/>
    <request-certificates-modal v-if="requesterData.certificate" isInput ref="certificates-modal" v-model="requesterData.certificate"/>
  
  </middleware-sidebar-outline>
  </template>
  
  
  <script>
  import {
    BContainer,
    BSidebar,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BForm,
    BTab,
    BFormSelect,
    BTabs,
    BFormSelectOption,
    BFormCheckbox,
    BSpinner,
    BLink,
  } from "bootstrap-vue";
  import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
  import DraggableItems from "@/views/pages/middleware/MiddlewareManagement/Components/DraggableItems.vue";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import { v4 as uuidv4 } from "uuid";
  import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
  import Drop from "@/views/pages/middleware/MiddlewareManagement/Components/Drop.vue";
  import Methods from "@/custom/class/Enum/Methods.js";
  import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
  import KeyValue from "@/views/pages/middleware/MiddlewareManagement/Components/Requester/KeyValueItems.vue";
  import { makeToast } from "@/layouts/components/Popups";
  import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
  import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js'
  
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
  
  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-markup.js';
  import 'prismjs/components/prism-xml-doc.js';
  import 'prismjs/components/prism-json.js';
  import 'prismjs/themes/prism-tomorrow.css';
  import Monaco from '@/layouts/components/editor/monaco.vue';
  import ImportRequestCurl from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/ImportRequestCurl.vue"
  import Middlewares from "@/custom/class/Enum/Middlewares.js"
  import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
  import * as MiddlewareFunctions from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"
  import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
  import {getQueriesFromUrl} from '@/custom/class/FunctionClasses/HelperFunctions.js';
  import { successToast } from '@/custom/class/FunctionClasses/CommonToasts';
    import RequestCertificatesModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/RequestCertificatesModal.vue"

  export default {
    mixins: [MiddlewaresMixin],
    components: {
      BContainer,
      BSidebar,
      BRow,
      BCol,
      BButton,
      BFormInput,
      BInputGroup,
      BFormGroup,
      BForm,
      BTab,
      BTabs,
      DraggableItems,
      CustomInput,
      VuePerfectScrollbar,
      BInputGroupAppend,
      BInputGroupPrepend,
      JsonEditor,
      Drop,
      BFormSelect,
      HelperTooltip,
      BFormSelectOption,
      KeyValue,
      PrismEditor,
      BFormCheckbox,
      ActionsReorderList,
      BSpinner,
      Monaco,
      MiddlewareSidebarOutline,
      RequestCertificatesModal,
      ImportRequestCurl,
      BLink,
    },
    props: {
      i18nKey: {
        type: String,
        default: 'requester'
      },
      uuid: {
        type: String,
        required: true,
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      value: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: "plain",
      },
      middlewareType: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        variables: undefined,
        variant_map: undefined,
        uuidMap: {},
        options: new Methods().items,
        allowForceClosing: false,


        requesterData: {
          method: null,
          uri: null,
          path: null,
          body: null,
          headers: null,
          queries: null,
          cookies: null,
          certificate: null,
        },
  
        fieldName: {
          method: 'METHOD',
          uri: 'URI',
          path: 'PATH',
          body: 'BODY',
          headers: 'HEADER',
          queries: 'QUERY',
          cookies: 'COOKIE',
          certificate: 'CERTIFICATE',
        },
  
        defaultValue: {
          method: 'GET',
          uri: '',
          path: '[]',
          body: '',
          headers: '[]',
          queries: '[]',
          cookies: '[]',
          certificate: '',
        },
  
        typeDefaultValues: {
          json: {},
          xml: '',
          plain: '',
          'form-data': [],
          'url-encoded': []
        },
        integratorRecommendation: undefined,
        agentList: [],
        advancedBody: false,
        hasBody: true,
        version: 0,
        isSaving: false,
        isIntegrator: false,
        defaultAgentBlockID: 1,
        defaultAgentActionID: 3,
  
        startData: undefined,
      };
    },
    computed: {
      agents: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
      defaultHeaderValue() {
        return DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: this.defaultAgentActionID,
          enum_agent_block_id: this.defaultAgentBlockID,
          register_1: {
            source: 7,
          },
          register_2: {},
          register_destiny: {
            source: 1,
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      },
      defaultQueryValue() {
        return DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: this.defaultAgentActionID,
          enum_agent_block_id: this.defaultAgentBlockID,
          register_1: {
            source: 7,
          },
          register_2: {},
          register_destiny: {
            source: 1,
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      },
      defaultPathValue() {
        return DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: this.defaultAgentActionID,
          enum_agent_block_id: this.defaultAgentBlockID,
          register_1: {
            source: 7,
          },
          register_2: {},
          register_destiny: {
            source: 1,
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      },
      defaultBodyValue() {
        return DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: this.defaultAgentActionID,
          enum_agent_block_id: this.defaultAgentBlockID,
          register_1: {
            source: 7,
          },
          register_2: {},
          register_destiny: {
            source: 1,
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      },
      defaultCookieValue() {
        return DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: this.defaultAgentActionID,
          enum_agent_block_id: this.defaultAgentBlockID,
          register_1: {
            source: 7,
          },
          register_2: {},
          register_destiny: {
            source: 1,
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      },
  
      isBodyList() {
        return this.typeDefaultValues[this.type] instanceof Array
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.checkIfIntegrator();
        this.initializeAgents();
        this.cloneStart()
        this.$nextTick(() => { 
          this.setHasBody();
        })
      },
      checkIfIntegrator(){
        const agents = this.agents
        if (Array.isArray(agents)){
          let idx = agents.findIndex((a) => String(a.enum_agent_action.id) == '0')
          if (idx > -1){
            this.isIntegrator = true
          }
        }
      },
      cloneStart(){
        this.startData = MiddlewareFunctions.extractMiddlewareValues(this.requesterData)
      },
      setHasBody() {
        if (this.isBodyList) return 
        let body = this.requesterData.body.register_1.value
        if (!body || !Object.keys(body).length) {
          this.hasBody = false
        }
      },
      highlighter(code) {
        let lang = undefined;
        try {
          JSON.parse(code);
          lang = languages.json;
        } catch (e) {
          var xmlString = this.requesterData.body.value;
          var domParser = new DOMParser();
          var dom = domParser.parseFromString(xmlString, 'text/xml');
  
          if(dom.documentElement.nodeName != 'parsererror') {
            lang = languages.xml;
          } else {
            lang = languages.plain;
          }
        }
  
        return highlight(code, lang);
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      isObject(item) {
        return item instanceof Object && !(item instanceof Array);
      },
      isArray(item) {
        return item instanceof Array;
      },        
      getBodyModifier(){      
        let r;
        if (this.type == "json"){
          r = (el) => {
              return {
                ...el,
                register_1: { 
                  value: JSON.parse(el.register_1.value),
                  source: el.register_1.source
                }
              }
            }
        } else if (this.type == "form-data" || this.type == "url-encoded"){
          r = (el) => {
            return {
              ...el,
              register_destiny: {
                value: el.register_destiny.value.split('.')[1],
                source: el.register_destiny.source,
              } 
            };
          }
        } else {
          r = (el) => {
              return {
                ...el,
                register_1: { 
                  value: el.register_1.value,
                  source: el.register_1.source
                }
              }
            }
        }
        return r
      },
      initializeAgents() {
        Object.keys(this.requesterData).forEach(pos => {
          if (['headers', 'queries', 'path' , 'cookies',].includes(pos)){
            //these are Multiple Agents type registers
            this.requesterData[pos] = MiddlewareFunctions.getValueFrom(
            {
              default: [],
              source: 1,
              value: `${this.fieldName[pos]}.`,
              from: {
                source: "register_destiny",
                value: "register_destiny",
              },
             
              data_modifier: (el) => {
                return {
                  ...el,
                  register_destiny: {
                    value: el.register_destiny.value.split('.')[1],
                    source: el.register_destiny.source,
                  } 
                };
              },
              modifier: (el) => {
                if (!el) return
  
                return el.split('.')[0] + '.';
              },
              multiple: true,
            },
            this.agents
          );
          } else {
            this.requesterData[pos] = MiddlewareFunctions.getValueFrom(
              {
                default: DefaultAgent.setAgent(this, {
                  id: null,
                  enum_agent_action_id: this.defaultAgentActionID,
                  enum_agent_block_id: this.defaultAgentBlockID,
                  register_1: {
                    value: this.defaultValue[pos],
                    source: "7",
                  },
                  register_2: {},
                  register_destiny: {
                    source: 1,
                    value: this.fieldName[pos],
                  },
                  execution_order: 1,
                  fatal_on_fail: true,
                }),
                source: 1,
                value: this.fieldName[pos],
                from: {
                  value: "register_destiny",
                  source: "register_destiny",
                },
                modifier: false,
                multiple: false,
                expected: {
                  source: 7,
                  from: "register_1",
                },
              },
              this.agents
            );          
          }
        });

        this.requesterData.body = MiddlewareFunctions.getValueFrom(
          {
            default: this.isBodyList ?
              this.typeDefaultValues[this.type]
              :
              DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: "7",
                  value: this.typeDefaultValues[this.type]
                },
                register_2: {},
                register_destiny: {
                  source: "1",
                  value: this.isBodyList ? undefined : "BODY",
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
            source: "1",
            value: this.isBodyList ? undefined : "BODY",
            from: {
              value: "register_destiny",
              source: "register_destiny",
            },
            multiple: this.isBodyList,
            data_modifier: this.getBodyModifier(),
            modifier: ['form-data','url-encoded'].includes(this.type) ?
              (el) => {
                if (!el) return;
                return el.split('.')[0] + '.';
              } :
              (el) => {return el},
            confirmationFunction: ((el) => {
              if (['form-data','url-encoded'].includes(this.type)){
                return el.register_destiny.value && el.register_destiny.value.startsWith('BODY.')
              } else if ([ "json" ].includes(this.type)){
                const isBody = el.register_destiny.value && el.register_destiny.value.startsWith('BODY')
                const isLiteral = el.register_1.source &&  el.register_1.source == 7 // source 7 -> LITERAL
                return isBody && isLiteral
              } 
              return
            })
          },
          this.agents
        );
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }
  
        const uuid = uuidv4();
        this.uuidMap[key] = uuid;
  
        return uuid;
      },
      definePayloadModifier(payload, mod){
        return {
          ...payload,
          register_destiny: {
            value: `${mod}.${payload.register_destiny.value}`,
            source: payload.register_destiny.source
          }
        }
      },
      buildAgents() {
        this.isSaving = true
        this.agentList = [];
        
        Object.keys(this.requesterData).forEach((key) => {
          switch (true) {
            case (this.requesterData[key] instanceof Array): {
              if (this.requesterData[key].length) {
                this.requesterData[key].forEach((item) => {
                  this.agentList.push(DefaultAgent.defineToAPI(this.definePayloadModifier(item, this.fieldName[key]), this.middlewareID));
                });
              }
            } break;
            case key == 'body': {
              if (this.hasBody){
                  this.agentList.push(this.defineBody());
              }            
              break;
            }
            case key == 'certificate': {
              if (this.requesterData[key].register_1.value && this.requesterData[key].register_1.value.length > 0 || String(this.requesterData[key].register_1.source) != '7'){
                this.agentList.push(DefaultAgent.defineToAPI(this.requesterData[key], this.middlewareID));
              }            
              break;
            }
            default: {
              this.agentList.push(DefaultAgent.defineToAPI(this.requesterData[key], this.middlewareID));
            } break;
          }
        })
        this.saveAgents();
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.$emit("saved", response.data);
            // this.isSaving = false
          })
          .catch((error) => {
            console.error(error);
            this.isSaving = false
          });
      },
      deleteAgent(item, index, type) {
        this.$store
          .dispatch("deleteAgent", {
            id: item.id,
            transmissionID: this.$route.params.transmissionID,
          })
          .then(() => {
            makeToast({
              title: this.$t("agent.toast.delete.success.title"),
              text: this.$t("agent.toast.delete.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
          })
          .catch(() => {
            this.$refs[this.getID(type)].addItemAt(item, index);
            makeToast({
              title: this.$t("agent.toast.delete.error.title"),
              text: this.$t("agent.toast.delete.error.message"),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      defineBody() {
        let body = this.requesterData.body.register_1.value;
        if(this.type == 'json') {
          body = JSON.stringify(body);
        }
  
        return {
          agent_id: this.requesterData.body.id || null,
          middleware: this.middlewareID,
          enum_agent_action: 3,
          enum_agent_block_id: 1,
          enum_source_destiny_id: 1,
          destiny_value: "BODY",
          enum_source_register_1: 7,
          register_1_value: body,
          enum_source_register_2: null,
          register_2_value: null,
          fatal_on_fail: true,
        };
      },
      sidebarShown() {
      this.$emit("shown")
        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");
  
        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      closingSidebar(){
        if (this.isSaving || this.allowForceClosing){
          this.$emit('close')
          return
        }
        let newValues = MiddlewareFunctions.extractMiddlewareValues(this.requesterData)
        if (!this.isSaving && (this.startData) != (newValues)){
          
          this.$root.$emit("bv::toggle::collapse", this.uuid );
          this.$refs['middleware-sidebar-outline'].showConfirmClose()
          
        } else {
          this.$emit('close')
        }
      },
      clearCertificate(){
        this.requesterData.certificate.register_2 = null
        this.requesterData.certificate.register_1.value = ''
      },
      parseQueriesFromUrl(){
        if (!(this.requesterData?.uri?.register_1?.value) || this.requesterData.uri.register_1.value.length == 0 || String(this.requesterData.uri.register_1.source) != '7'){
          this.integratorRecommendation = undefined;
          return
        }
        let url = this.requesterData.uri.register_1.value
        let queries =(getQueriesFromUrl(url))

        if (Object.keys(queries).length > 0){
          url = url.substring(0, url.indexOf('?')) //returns url before first '?'
          this.requesterData.uri.register_1.value = url

          let rQueries = structuredClone(this.requesterData.queries) 
          Object.keys(queries).forEach((qKey)=>{
            let agnt = structuredClone(this.defaultHeaderValue)
            agnt.register_1.value = queries[qKey]
            agnt.register_destiny.value = qKey
            
            rQueries.push(agnt)
          })

          this.requesterData.queries = rQueries
          successToast({text: 'Queries automatically imported'})
          this.$nextTick(()=>{
            this.version = this.version + 1
          })
        }
        
        url = url.trim()
        if (!url.startsWith('http')){
          url = 'https://' + url
        }
        url = new URL(url).host
        if (url.length > 8){
          this.searchMathingIntegratorServices(url)
        } else {
          this.integratorRecommendation = undefined
        }
      },
      searchMathingIntegratorServices(url){
        const payload = {
          url: url
        }
        this.$store.dispatch('serviceModule/searchIntegratorByUrl', payload)
          .then((resp)=>{
            if (resp.length != 0){
              this.integratorRecommendation = resp[0];
            } else {
              this.integratorRecommendation = undefined
            }
          }).catch((err)=>{
            console.error(err)
            this.integratorRecommendation = undefined
          })
      },
      importFromCurl(data){
        
        // ---- URL ----
        this.$set(this.requesterData.uri, 'register_1', {
          value: data.url,
          source: 7
        })
        
        // ---- METHOD ----
        if(data.method){
          let method = (this.options.map(el => el.script_output)).find(el => el.toLowerCase() == data.method.toLowerCase())
          if (method){
            this.requesterData.method.register_1.value = method
          }
        }

        // ---- HEADERS ----
        if (data.header){
          let rHeaders = []
          let headerKeys = Object.keys(data.header)
          
          let contentTypeIdx = headerKeys.findIndex(el=> el.toLowerCase() == 'content-type')
          if (contentTypeIdx > -1){
            headerKeys.splice(contentTypeIdx, 1)
          }

          headerKeys.forEach((hKey)=>{
            let agnt = structuredClone(this.defaultHeaderValue)
            agnt.register_1.value = data.header[hKey]
            agnt.register_destiny.value = hKey
            
            rHeaders.push(agnt)
          })

          this.requesterData.headers = rHeaders
        }

        // ---- QUERIES ----
        if (data.query){
          let rQueries = []
          
          Object.keys(data.query).forEach((qKey)=>{
            let agnt = structuredClone(this.defaultHeaderValue)
            agnt.register_1.value = data.query[qKey]
            agnt.register_destiny.value = qKey
            
            rQueries.push(agnt)
          })

          this.requesterData.queries = rQueries
        }

        // ---- BODY ----
        if (data.data){
          this.requesterData.body.register_1.value = data.data 
          this.hasBody = true
        }

        this.$nextTick(()=>{
          this.version = this.version + 1
        })
      }
    },
  };
  </script>
  
<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.sidebar-container{
  max-height: 97vh !important;
  height: fit-content !important;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0 !important;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}

.import-curl-btn{
  font-size: 12px;
  font-weight: bold;
  color: $favorite;
  border-radius: 0;
  padding: 5px 5px 0px 5px;
  margin: 0 5px;
  opacity: 0.9;
  transition: all 0.2s;
  
  .hover-indicator{
    padding-top: 2px;
    margin: auto;
    transition: all 0.3s;
    border-bottom: 1px solid $favorite;
    width: 0;
  }
  &:hover{
    opacity: 1;
    .hover-indicator{
      width: 100%;
    }
  }
}

</style>

<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.height-400 {
  height: 400px;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;
  }

  .limit-height {
    height: calc(100% - 260px) !important;
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.jv-dark {
  background: #161d31;
  white-space: nowrap;
  color: $white;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
  box-shadow: 0 2px 7px rgb(0 0 0 / 15%) !important;
  border-color: transparent !important;
  position: relative !important;

  .jv-ellipsis {
    color: $white;
    background-color: #293148;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 5px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #a6e22e;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: $white;
    }
    &.jv-boolean {
      color: #ae81ff;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #ae81ff;
    }
    &.jv-number-float {
      color: #ae81ff;
    }
    &.jv-number-integer {
      color: #ae81ff;
    }
    &.jv-object {
      color: $white;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #e6db74;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #161d31;
        }
      }
    }
  }
}

.h3-separator {
  color: #ffffff2b !important;
  font-size: 40px;
}

.test-terminal {
  max-height: 80vh;
  .submit-button {
    margin-top: 22px;
  }
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.json-input-disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
}
</style>